





















































import { Component, Vue } from 'vue-property-decorator';
import ProfileConnector from '@/connector/Profile.vue';
// eslint-disable-next-line import/no-cycle
import TransactionService from '@/services/TransactionService';

const transactionService = new TransactionService();

@Component({
  components: {
    ProfileConnector,
  },
})
export default class WithdrawPage extends Vue {
  public loading: boolean = false;

  public async refundLoss() {
    try {
      this.loading = true;
      await transactionService.refundLoss();
      this.loading = false;
      await this.$store.dispatch('Profile/getProfile');
      this.$store.commit('Snackbar/showNotification', { message: 'ถอนเงินสำเร็จ', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
