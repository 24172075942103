import { AppService } from './AbstractService';

export default class TransactionService extends AppService {
  public requestDeposit(data: FormData) {
    return this.makeRequest('POST', '/transactions/deposit', data);
  }

  public async checkBalance() {
    return this.makeRequest('GET', '/users/balance');
  }

  public requestWithdraw(data: { amount: number }) {
    return this.makeRequest('POST', '/transactions/withdraw', data);
  }

  public affiliateWithdraw(amount: number) {
    return this.makeRequest('POST', '/transactions/affiliate-withdraw', { amount });
  }

  public getTransaction(type: string) {
    return this.makeRequest('GET', `/transactions/${type}`);
  }

  public refundLoss() {
    return this.makeRequest('POST', '/transactions/refund-loss');
  }

  public getDepositQrCode(data: any) {
    return this.makeRequest('POST', '/transactions/qrcode', data);
  }

  public getTopGainer() {
    return this.makeRequest('GET', '/transactions/top-gainer');
  }
}
